<template>
  <div class="privacyClause">
    <div class="agree-title">{{privacyData.title}}</div>
    <div class="agree-cont" v-html="privacyData.body"></div>
  </div>
</template>

<script>
export default {
  name: 'thirdPartyList',
  data() {
    return {
      privacyData: {}
    }
  },
  created() {
    this.getArticleContByIdEvent();
  },
  methods: {
    getArticleContByIdEvent() {
      this.$request.getArticleContById({categoryId: 66}).then(res => {
        if(res.data.code == 0) {
          this.privacyData = res.data.data;
        }
      })
    }
  }
}
</script>

<style lang='scss'>
  .privacyClause {
    height: 100%;
    background-color: #F9F9F9;
    overflow: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    padding: 8px 24px;
    .agree-title {
      font-size: 18px;
      color: #333;
      margin: 6px 0 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    img {
      width: 100%!important;
    }
  }
</style>
